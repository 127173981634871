import React from 'react';
import { graphql, Link } from 'gatsby';

import '../../styles/skeleton.css';

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            slug
          }
        }
      }
    }
  }
`;

export default ({ data }) => {
  return (
    <>
      <div class='container'>
        <h1>Writings</h1>
        <table class='u-full-width'>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node: { frontmatter: { date, slug, title } } }) => (
              <tr key={slug}>
                <td>
                  <Link to={slug}><h5>{title} - {date}</h5></Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

